<template>
  <div>
      <div id="header-sticky" class="header-1">
        <div class="container-fluid">
          <div class="mega-menu-wrapper">
            <div class="header-main">
              <div class="logo">
                <a href="" class="header-logo">
                  <img src="https://file.me-moments.com/big3/logo1.png" alt="logo-img">
                </a>
                <a href="" class="header-logo-2">
                  <img src="https://file.me-moments.com/big3/logo.png" alt="logo-img">
                </a>
              </div>
              <div class="header-left">
                <div class="mean__menu-wrapper">
                  <div class="main-menu">
                    <nav id="mobile-menu">
                      <ul>
                        <li class="has-dropdown active menu-thumb">
                          <a href="/">
                            首页
                          </a>
                        </li>
                        <li class="has-dropdown active d-lg-none">
                          <a href="/" class="border-none">
                            首页
                          </a>
                        </li>
                        <li>
                          <a href="/aboutPage">关于我们</a>
                        </li>
                        <li>
                          <a href="/serviceList">
                            服务
                          </a>
                        </li>
                        <li>
                          <a href="/newsList">
                            新闻
                          </a>
                        </li>
                        <li class="has-dropdown">
                          <a href="/photoList">
                            照片墙
                          </a>
                        </li>
                        <li class="has-dropdown">
                          <a href="/shopPage">
                            周边
                          </a>
                        </li>
                        <li>
                          <a href="/contactPage">联系我们</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <div class="header-right d-flex justify-content-end align-items-center">
                <a href="javascript:;" class="search-icon"><i class="fal fa-search"></i></a>
                <a href="javascript:;" class="cart-icon"><i class="far fa-shopping-cart"></i></a>
                <div class="header__hamburger d-lg-none my-auto">
                  <div class="sidebar__toggle">
                    <i class="far fa-bars"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'IndexHeader'
}
</script>
