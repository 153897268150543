<template>
  <div>
    <!-- Preloader Start -->
    <PreLoader />

    <!--<< Mouse Cursor Start >>-->
    <div class="mouse-cursor cursor-outer"></div>
    <div class="mouse-cursor cursor-inner"></div>

    <!-- Header Area Start -->
    <IndexHeader />

    <!--<< Breadcrumb Section Start >>-->
    <BreadcrumbPage :name="'周边'" />

    <!--<< Shop Section Start >>-->
    <section class="shop-section fix section-padding">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s" v-for="i in DataConfig.goodsList" :key="i.id">
            <div class="single-shop-items">
              <div class="shop-image">
                <img :src="i.image" alt="shop-img">
                <div class="shop-button">
                  <a :href="i.jumpUrl" target="_blank" class="theme-btn header-color-2">查看详情</a>
                </div>
              </div>
              <div class="shop-content">
                <h4>
                  <a :href="i.jumpUrl" target="_blank">{{i.name}}</a>
                </h4>
                <div class="price d-flex align-items-center justify-content-between">
                  <p>{{i.price}}元</p>
                  <div class="star">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer Section Start -->
    <FooterPage />
  </div>
</template>

<script>
import IndexHeader from "@/components/IndexHeader.vue"
import FooterPage from "@/components/FooterPage.vue"
import PreLoader from "@/components/PreLoader.vue"
import DataConfig from "@/views/data/DataConfig"
import BreadcrumbPage from "@/components/BreadcrumbPage.vue"
export default {
  name: 'ShopPage',
  components: {
    IndexHeader,
    PreLoader,
    FooterPage,
    BreadcrumbPage
  },
  computed: {
    DataConfig() {
      return DataConfig;
    },
  },
}
</script>
