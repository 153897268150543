<template>
  <div>
    <!-- Preloader Start -->
    <PreLoader />

    <!--<< Mouse Cursor Start >>-->
    <div class="mouse-cursor cursor-outer"></div>
    <div class="mouse-cursor cursor-inner"></div>

    <!-- Header Area Start -->
    <IndexHeader />

    <!--<< Breadcrumb Section Start >>-->
    <BreadcrumbPage :name="'关于我们'"/>

    <!-- Team Section Start -->
    <section class="team-section fix section-padding pt-0" style="margin-top: 120px;">
      <div class="container">
        <div class="section-title text-center">
          <span class="wow fadeInUp">我们的团队</span>
          <h2 class="wow fadeInUp" data-wow-delay=".3s">带你认识我们各个部门吧！</h2>
        </div>
<!--        <div class="row">-->
<!--          <div class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s" v-for="i in DataConfig.teamData" :key="i.id">-->
<!--            <div class="team-card-items style-2">-->
<!--              <div class="team-image bg-cover" :style="'background-image: url('+i.image+');'">-->
<!--                <div class="team-content-2">-->
<!--                  <h4>-->
<!--                    <a href="team-details.html">-->
<!--                      {{i.name}}-->
<!--                    </a>-->
<!--                  </h4>-->
<!--                  <p>{{i.position}}</p>-->
<!--                </div>-->
<!--                <div class="social-profile">-->
<!--                  <ul>-->
<!--                    <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>-->
<!--                    <li><a href="#"><i class="fab fa-instagram"></i></a></li>-->
<!--                    <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>-->
<!--                  </ul>-->
<!--                  <span class="plus-btn"><i class="fas fa-share-alt"></i></span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="row">
          <div class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s" v-for="i in DataConfig.teamData2" :key="i.id">
            <div class="team-card-items style-2">
              <div class="team-image bg-cover" :style="'background-image: url('+i.image+');'">
                <div class="team-content-2">
                  <h4>
                    <a :href="'teamDetail?id='+i.id">
                      {{i.name}}
                    </a>
                  </h4>
                  <p>{{i.position}}</p>
                </div>
                <div class="social-profile">
                  <ul>
                    <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                    <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                  </ul>
                  <span class="plus-btn"><i class="fas fa-share-alt"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer Section Start -->
    <FooterPage />
  </div>
</template>

<script>

import IndexHeader from "@/components/IndexHeader.vue"
import PreLoader from "@/components/PreLoader.vue"
import DataConfig from "@/views/data/DataConfig"
import FooterPage from "@/components/FooterPage.vue"
import BreadcrumbPage from "@/components/BreadcrumbPage.vue"
export default {
  name: 'AboutPage',
  components: {
    IndexHeader,
    PreLoader,
    FooterPage,
    BreadcrumbPage
  },
  computed: {
    DataConfig() {
      return DataConfig;
    },
  },
}
</script>
