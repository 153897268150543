import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexPage from '@/views/IndexPage.vue'
import AboutPage from '@/views/AboutPage.vue'
import TeamDetail from '@/views/TeamDetail.vue'
import ServiceList from '@/views/ServiceList.vue'
import NewsList from '@/views/NewsList.vue'
import NewsDetail from '@/views/NewsDetail.vue'
import PhotoList from '@/views/PhotoList.vue'
import ShopPage from '@/views/ShopPage.vue'
import ContactPage from '@/views/ContactPage.vue'
import PhotoDetail from '@/views/PhotoDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'IndexPage',
    component: IndexPage
  },
  {
    path: '/aboutPage',
    name: 'aboutPage',
    component: AboutPage
  },
  {
    path: '/teamDetail',
    name: 'teamDetail',
    component: TeamDetail
  },
  {
    path: '/serviceList',
    name: 'serviceList',
    component: ServiceList
  },
  {
    path: '/newsList',
    name: 'newsList',
    component: NewsList
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: NewsDetail
  },
  {
    path: '/photoList',
    name: 'photoList',
    component: PhotoList
  },
  {
    path: '/shopPage',
    name: 'shopPage',
    component: ShopPage
  },
  {
    path: '/contactPage',
    name: 'contactPage',
    component: ContactPage
  },
  {
    path: '/photoDetail',
    name: 'photoDetail',
    component: PhotoDetail
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
