<template>
  <div>
    <div class="breadcrumb-wrapper section-padding bg-cover" style="background-image: url('https://file.me-moments.com/big3/breadcrumb.png');">
      <div class="layer-shape wow fadeInLeft" data-wow-delay=".3s">
        <img src="/picture/layer-shape-3.png" alt="shape-img">
      </div>
      <div class="breadcrumb-shape wow fadeInRight" data-wow-delay=".5s">
        <img src="/picture/breadcrumb-shape.png" alt="shape-img">
      </div>
      <div class="container">
        <div class="page-heading">
          <h1 class="wow fadeInUp" data-wow-delay=".3s">{{name}}</h1>
          <ul class="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
            <li>
              <a href="/">
                首页
              </a>
            </li>
            <li>
              <i class="fal fa-minus"></i>
            </li>
            <li>
              {{name}}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BreadcrumbPage',
  props: ["name"],
}
</script>
