<template>
  <div id="index">
    <!-- Preloader Start -->
    <PreLoader />

    <!--<< Mouse Cursor Start >>-->
    <div class="mouse-cursor cursor-outer"></div>
    <div class="mouse-cursor cursor-inner"></div>

    <!-- Offcanvas Area Start -->
<!--    <div class="fix-area">-->
<!--      <div class="offcanvas__info">-->
<!--        <div class="offcanvas__wrapper">-->
<!--          <div class="offcanvas__content">-->
<!--            <div class="offcanvas__top mb-5 d-flex justify-content-between align-items-center">-->
<!--              <div class="offcanvas__logo">-->
<!--                <a href="">-->
<!--                  <img src="/public/picture/header-logo.svg" alt="logo-img">-->
<!--                </a>-->
<!--              </div>-->
<!--              <div class="offcanvas__close">-->
<!--                <button>-->
<!--                  <i class="fas fa-times"></i>-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="mobile-menu fix mb-3"></div>-->
<!--            <div class="offcanvas__contact">-->
<!--              <h4>Contact Info</h4>-->
<!--              <ul>-->
<!--                <li class="d-flex align-items-center">-->
<!--                  <div class="offcanvas__contact-icon">-->
<!--                    <i class="fal fa-map-marker-alt"></i>-->
<!--                  </div>-->
<!--                  <div class="offcanvas__contact-text">-->
<!--                    <a target="_blank" href="#">Main Street, Melbourne, Australia</a>-->
<!--                  </div>-->
<!--                </li>-->
<!--                <li class="d-flex align-items-center">-->
<!--                  <div class="offcanvas__contact-icon mr-15">-->
<!--                    <i class="fal fa-envelope"></i>-->
<!--                  </div>-->
<!--                  <div class="offcanvas__contact-text">-->
<!--                    <a href="mailto:info@logimax.com"><span class="mailto:info@logimax.com">info@logimax.com</span></a>-->
<!--                  </div>-->
<!--                </li>-->
<!--                <li class="d-flex align-items-center">-->
<!--                  <div class="offcanvas__contact-icon mr-15">-->
<!--                    <i class="fal fa-clock"></i>-->
<!--                  </div>-->
<!--                  <div class="offcanvas__contact-text">-->
<!--                    <a target="_blank" href="#">Mod-friday, 09am -05pm</a>-->
<!--                  </div>-->
<!--                </li>-->
<!--                <li class="d-flex align-items-center">-->
<!--                  <div class="offcanvas__contact-icon mr-15">-->
<!--                    <i class="far fa-phone"></i>-->
<!--                  </div>-->
<!--                  <div class="offcanvas__contact-text">-->
<!--                    <a href="tel:+11002345909">+11002345909</a>-->
<!--                  </div>-->
<!--                </li>-->
<!--              </ul>-->
<!--              <div class="header-button mt-4">-->
<!--                <a href="contact.html" class="theme-btn text-center">-->
<!--                  Contact Us-->
<!--                </a>-->
<!--              </div>-->
<!--              <div class="social-icon d-flex align-items-center">-->
<!--                <a href="#"><i class="fab fa-facebook-f"></i></a>-->
<!--                <a href="#"><i class="fab fa-twitter"></i></a>-->
<!--                <a href="#"><i class="fab fa-youtube"></i></a>-->
<!--                <a href="#"><i class="fab fa-linkedin-in"></i></a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="offcanvas__overlay"></div>-->

    <IndexHeader />

    <!-- Hero Section Start -->
    <section class="hero-section hero-1">
      <div class="array-button">
        <button class="array-prev"><i class="fal fa-arrow-right"></i></button>
        <button class="array-next"><i class="fal fa-arrow-left"></i></button>
      </div>
      <div class="swiper hero-slider">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="i in DataConfig.swiperData" :key="i.id">
            <div class="shape-image" data-animation="fadeInLeft" data-delay="2.1s">
              <img src="/picture/shape.png" alt="shape-img">
            </div>
            <div class="hero-image bg-cover" :style="'background-image: url('+i.image+');'"></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-12">
                  <div class="hero-content text-center">
                    <h1 class="text-white" data-animation="fadeInUp" data-delay="1.3s">
                      {{i.oneText}} <br> {{i.twoText}}
                    </h1>
                    <div class="hero-button">
                      <div class="d-sm-inline video-play-btn" data-animation="fadeInUp" data-delay="1.9s">
                        <a href="#" class="video-popup play-video" tabindex="-1"><i class="far fa-play"></i></a>
                        <span class="ms-3 d-line">观看视频</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Cta Section Start -->
    <section class="cta-section section-padding pt-0">
      <div class="container">
        <div class="cta-wrapper">
          <div class="row g-4">
<!--            <div class="col-md-3">-->
<!--              <div class="cta-left">-->
<!--                <h2><span class="count">25</span>+</h2>-->
<!--                <p>Years of Experience</p>-->
<!--              </div>-->
<!--            </div>-->
            <div class="col-md-12">
              <div class="cta-right">
                <div class="shape-image">

                </div>
                <div class="cta-content">
                  <span></span>
                  <h3>商务合作 携手共赢</h3>
                </div>
                <div class="call-area">
                  <p>范总</p>
                  <a href="#">18600728633</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- About Section Start -->
    <section class="about-section section-padding pt-0 fix">
      <div class="container">
        <div class="about-wrapper">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="about-image wow fadeInUp" data-wow-delay=".3s">
                <img src="https://file.me-moments.com/big3/index_688.png" alt="about-img">
                <div class="video-box wow fadeInUp" data-wow-delay=".5s">
                  <img src="https://file.me-moments.com/big3/index_518.png" alt="about-img">
                  <a href="#" class="video-btn ripple video-popup">
                    <i class="far fa-play"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mt-5 mt-lg-0">
              <div class="about-content">
                <div class="section-title">
                  <span class="wow fadeInUp">关于我们</span>
                  <h2 class="wow fadeInUp" data-wow-delay=".3s">
                    中焱体育介绍
                  </h2>
                </div>
                <p class="mt-3 mt-md-0 wow fadeInUp" data-wow-delay=".5s">
                  中焱体育是一家致力于推广体育文化、推动体育产业发展、打造顶级体育IP、研究体育科技的综合性专业化品牌管理企业。公司总部位于北京，拥有优秀的专业团队和丰富的运营经验。
                  2024年，中焱体育获美国顶级三对三BIG3联盟中国运营独家授权，整体负责中国地区组织 BIG3篮球赛事、IP运营、商业开发、新媒体运营等系列业务。
                  <br />
                  作为独家运营商，中焱体育将承担起在中国推广发展BIG3联赛、推动篮球文化普及提升的重任，全力打造经典赛事，促进中国篮球和体育事业高质量发展。
                </p>
                <ul class="about-list wow fadeInUp" data-wow-delay=".7s">
                  <li>
                    <i class="fas fa-check-circle"></i>
                    BIG3·中国篮球赛事
                  </li>
                  <li>
                    <i class="fas fa-check-circle"></i>
                    球星商业活动
                  </li>
                  <li>
                    <i class="fas fa-check-circle"></i>
                    IP授权及新媒体运营
                  </li>
                </ul>
                <div class="about-author">
<!--                  <div class="author-image wow fadeInUp" data-wow-delay=".3s">-->
<!--                    <img src="/picture/author.png" alt="author-img">-->
<!--                    <img src="/picture/signature.png" alt="signature">-->
<!--                  </div>-->
<!--                  <div class="about-button wow fadeInUp" data-wow-delay=".5s">-->
<!--                    <a href="about.html" class="theme-btn">-->
<!--                      Discover More-->
<!--                    </a>-->
<!--                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Service Section Start -->
    <section class="service-section section-padding pt-0">
      <div class="container">
        <div class="row g-4">
          <div class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".3s" v-for="i in DataConfig.platformData" :key="i.id">
            <div class="service-card-items text-center">
<!--              <div class="icon">-->
                <img :src="i.icon" />
<!--                <i class="flaticon-ship-3"></i>-->
<!--              </div>-->
              <div class="content">
                <h5>
                  <a :href="i.url" target="_blank">
                    {{i.text}}
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-4">
          <div class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".3s" v-for="i in DataConfig.platformData2" :key="i.id">
            <div class="service-card-items text-center">
<!--              <div class="icon">-->
<!--                <i class="flaticon-ship-3"></i>-->
<!--              </div>-->
              <img :src="i.icon" />
              <div class="content">
                <h5>
                  <a :href="i.url" target="_blank">
                    {{i.text}}
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Team Section Start -->
    <section class="team-section fix section-padding pt-0">
      <div class="container">
        <div class="section-title text-center">
          <span class="wow fadeInUp">我们的团队</span>
          <h2 class="wow fadeInUp" data-wow-delay=".3s">
            带你认识我们各个部门吧！
          </h2>
        </div>
        <div class="row">
          <div class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".3s" v-for="i in DataConfig.teamData" :key="i.id">
            <div class="single-team-items">
              <div class="team-image">
                <img :src="i.image" alt="team-img">
                <div class="team-content">
                  <h4>
                    <a href="#">{{i.name}}</a>
                  </h4>
                  <p>{{i.position}}</p>
                  <div class="social-icon d-flex align-items-center">
                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                    <a href="#"><i class="fab fa-twitter"></i></a>
                    <a href="#"><i class="fab fa-vimeo-v"></i></a>
                    <a href="#"><i class="fab fa-pinterest-p"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="text-area text-center mt-5 wow fadeInUp" data-wow-delay=".4s">-->
<!--          <span>Contact our expert team member to take our  <a href="service-details.html">BEST SERVICE</a></span>-->
<!--        </div>-->
      </div>
    </section>

    <FooterPage />
  </div>
</template>

<script>

import IndexHeader from "@/components/IndexHeader.vue"
import FooterPage from "@/components/FooterPage.vue"
import PreLoader from "@/components/PreLoader.vue"
import DataConfig from "@/views/data/DataConfig"
export default {
  name: 'IndexPage',
  components: {
    IndexHeader,
    PreLoader,
    FooterPage
  },
  computed: {
    DataConfig() {
      return DataConfig;
    },
  },
}
</script>

<style scoped>

</style>
