<template>
  <div>
    <PreLoader />

    <!--<< Mouse Cursor Start >>-->
    <div class="mouse-cursor cursor-outer"></div>
    <div class="mouse-cursor cursor-inner"></div>

    <!-- Header Area Start -->
    <IndexHeader />


    <!--<< Breadcrumb Section Start >>-->
    <BreadcrumbPage :name="'照片墙'" />

    <!-- Project Section Start -->
    <section class="project-details fix section-padding">
      <div class="container">
        <div class="project-wrapper">
          <div class="row">
            <div class="col-lg-12">
              <div class="project-details-image bg-cover" :style="'background-image: url('+i+');'" v-for="i in DataConfig.photoDetail" :key="i"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer Section Start -->
    <FooterPage />
  </div>
</template>

<script>
import IndexHeader from "@/components/IndexHeader.vue"
import FooterPage from "@/components/FooterPage.vue"
import PreLoader from "@/components/PreLoader.vue"
import DataConfig from "@/views/data/DataConfig"
import BreadcrumbPage from "@/components/BreadcrumbPage.vue"
export default {
  name: 'PhotoDetail',
  components: {
    IndexHeader,
    PreLoader,
    FooterPage,
    BreadcrumbPage
  },
  computed: {
    DataConfig() {
      return DataConfig;
    },
  },
}
</script>
