<template>
  <div>
    <PreLoader />

    <!--<< Mouse Cursor Start >>-->
    <div class="mouse-cursor cursor-outer"></div>
    <div class="mouse-cursor cursor-inner"></div>

    <!-- Header Area Start -->
    <IndexHeader />

    <!--<< Breadcrumb Section Start >>-->
    <BreadcrumbPage :name="'服务'" />

    <!-- Service Provide Section Start -->
    <section class="service-provide section-padding">
      <div class="shape-image">
        <img src="/picture/arrow-shape1.png" alt="shape-img">
      </div>
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-lg-6">
            <div class="section-title style-2">
              <span class="wow fadeInUp"></span>
              <h2 class="wow fadeInUp" data-wow-delay=".3s">
                我们竭诚为你服务！
              </h2>
            </div>
          </div>
          <div class="col-lg-5 wow fadeInUp" data-wow-delay=".5s">
            BIG3·中国（中焱体育）借力NBA球星的示范效应，通过赛事组织推行，打造国内赛事影响力，为中国篮球爱好者提供与球星同台竞技的机会。
            <br>
            BIG3·中国落地后，快速在抖音、快手、B站等新媒体平台上聚集了超高人气。
            <br>
            BIG3·中国旨在带来国际潮流文化和优质体育相关资源，引入BIG3及球星个人IP等，以促进中国篮球及体育事业高质量发展。
          </div>
        </div>
        <div class="row">
          <div class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".3s" v-for="i in DataConfig.serviceList" :key="i.id">
            <div class="service-provide-items text-center">
              <div class="icon">
                <i class="flaticon-cargo-boat"></i>
              </div>
              <div class="content">
                <h5>
                  <a href="service-details.html">
                    {{i.oneText}} <br> {{i.twoText}}
                  </a>
                </h5>
                <p>
                  {{i.content}}
                </p>
                <div class="arrow-icon">
                  <a href="service-details.html"> <i class="far fa-long-arrow-right"></i></a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <!-- Cta Section Start -->
    <section class="cta-section-2 style-2 fix section-padding bg-cover" style="background-image: url('https://file.me-moments.com/big3/service_bg.jpg');">
      <div class="container">
        <div class="cta-wrapper-2">
          <div class="cta-content">
            <h2 class="wow fadeInUp" data-wow-delay=".3s">
              快来观看视频 <br>
              感受激情澎湃的BIG3吧！
            </h2>
            <a href="#" class="video-icon video-popup1 wow fadeInUp" data-wow-delay=".5s">
              <i class="far fa-play"></i>
            </a>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer Section Start -->
    <FooterPage />
  </div>
</template>

<script>

import IndexHeader from "@/components/IndexHeader.vue"
import FooterPage from "@/components/FooterPage.vue"
import PreLoader from "@/components/PreLoader.vue"
import DataConfig from "@/views/data/DataConfig"
import BreadcrumbPage from "@/components/BreadcrumbPage.vue";
export default {
  name: 'ServiceList',
  components: {
    BreadcrumbPage,
    IndexHeader,
    PreLoader,
    FooterPage
  },
  computed: {
    DataConfig() {
      return DataConfig;
    },
  },
}
</script>
