<template>
  <div>
    <PreLoader />

    <!--<< Mouse Cursor Start >>-->
    <div class="mouse-cursor cursor-outer"></div>
    <div class="mouse-cursor cursor-inner"></div>

    <!-- Header Area Start -->
    <IndexHeader />

    <!--<< Breadcrumb Section Start >>-->
    <BreadcrumbPage :name="'新闻详情'"/>

    <!--<< Blog Wrapper Here >>-->
    <section class="blog-wrapper news-wrapper section-padding border-bottom">
      <div class="container">
        <div class="news-area">
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="blog-post-details border-wrap mt-0">
                <div class="single-blog-post post-details mt-0">
                  <div class="post-content pt-0">
                    <h2 class="mt-0">{{newsData.title}}</h2>
                    <div class="post-meta mt-3">
                      <span><i class="fal fa-user"></i>BIG3</span>
                      <span><i class="fal fa-calendar-alt"></i>{{newsData.month}}  {{newsData.day}}</span>
                    </div>
                    <div v-html="newsData.content">

                    </div>
<!--                    <p>-->
<!--                      尊敬的领导、挚爱的朋友：-->
<!--                    </p>-->
<!--                    <p>-->
<!--                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-->
<!--                      值此金秋时节，我们很荣幸能与您分享一份合作意向书。-->
<!--                    </p>-->
<!--                    <p>-->
<!--                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-->
<!--                      我们于近期拿到了全球顶级三人篮球商业联盟BIG3的中国区独家运营授权。BIG3是NBA退役球星艾佛森和说唱歌手艾斯库伯创办的3v3篮球赛事，获得麦迪、加内特、雷阿伦、德雷克斯勒等在内的众多NBA顶级球星支持参与，已成为全美仅次于NBA的顶级篮球赛事和全球最具影响力及观赏度的三人篮球赛事，自2017年成立以来在欧美市场已经取得了极大的成功。-->
<!--                    </p>-->
<!--                    <p>-->
<!--                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-->
<!--                      如今我们把这个赛事带到中国，旨在打造一个适应中国市场的经典IP赛事，承担在中国推广发展BIG3联赛、推动三人篮球文化普及提升的使命。我们将整体负责在BIG3在中国的篮球赛事组织、商业开发、顶级IP运营等业务，深度拓展BIG3联赛在中国市场，为中国篮球带来全新发展机遇和商业价值。-->
<!--                    </p>-->
<!--                    <p>-->
<!--                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-->
<!--                      近年来，党和国家高度重视大众体育和全民健身，我们敏锐把握并紧跟国家关于建设体育强国、推动青少年身体素质培训、加快发展体育产业、促进中外体育交流、培育经济发展新动能等清晰政策导向。我们和新华网达成深度战略合作，依托其强大媒体资源、权威影响力、优质内容价值和丰富传播渠道，引入国际知名体育IP，为赛事举办城市提高国际知名度，协同政府打造城市体育新名片，为体育产业和区域经济发展注入新动能。同时，BIG3中国新媒体运营现阶段在全网已有2000多万的粉丝量，我们已经和新华网、抖音、快手、B站、小红书等顶流媒体平台达成战略合作关系， 在用好传统媒体资源优势的基础上，我们将通过持续的新媒体运营活动，进一步拓宽用户群体、提升品牌知名度，不断提升品牌价值、市场地位和盈利能力，为长期发展提供有力支持。-->
<!--                    </p>-->
<!--                    <p>-->
<!--                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-->
<!--                      明年BIG3•中国计划在全国多个城市举办国际友谊赛，邀请BIG3顶级球星/球队来华，为中国篮球粉丝提供最高水平三人篮球盛宴，拉进BIG3品牌与海量球迷的距离，为更多的篮球爱好者提供平台。我们计划在国内推出NBA退役巨星及教练面向国内青少年的篮球培训。将NBA的篮球培训体系和中国青少年的篮球教培有机结合，提供青少年线上线下融合教学，选拔优秀青少年队员赴美篮球游学，创造更多平台让更多的小朋友和年轻人可以接触明星球员、明星教练。我们计划推出BIG3周边产品包括球星卡、运动毛巾、BIG3的T恤和运动鞋等产品，将品牌的影响力扩展到更广泛的领域，提升品牌的附加值和整体盈利水平。-->
<!--                    </p>-->
<!--                    <p>-->
<!--                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-->
<!--                      特此与好朋友们诚挚分享这份开启新征程的喜悦。我们完全有理由期待并相信，这是一项具广阔发展前景和极佳商业价值的阳光产业。如有品牌赞助或明星合作等商业活动意向的朋友们可以联系我们，非常期待合作共赢。-->
<!--                    </p>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer Section Start -->
    <FooterPage />
  </div>
</template>

<script>
import IndexHeader from "@/components/IndexHeader.vue"
import FooterPage from "@/components/FooterPage.vue"
import PreLoader from "@/components/PreLoader.vue"
import DataConfig from "@/views/data/DataConfig"
import BreadcrumbPage from "@/components/BreadcrumbPage.vue"
export default {
  name: 'NewsDetail',
  components: {
    IndexHeader,
    PreLoader,
    FooterPage,
    BreadcrumbPage
  },
  computed: {
    DataConfig() {
      return DataConfig;
    },
  },
  data() {
    return {
      id: this.$route.query.id,
      newsData:{}
    }
  },
  mounted() {
    //找到对应数据
    let news =  this.DataConfig.newsList;
    console.log(news)
    news.forEach(value => {
      value.data.forEach(value1 => {
        if(value1.id == this.id){
          this.newsData = value1;
        }
      })
    })
    console.log(this.newsData)
    console.log(this.id)
  }
}
</script>
