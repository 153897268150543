<template>
  <div>
    <!-- Footer Section Start -->
    <footer class="footer-section fix footer-bg">
      <div class="footer-bottom">
        <div class="container">
          <div class="footer-bottom-wrapper" style="display: block;">
            <p style="text-align: center">
              Copyright © 1997-2024 by www.big3cn.com. all rights reserved
            </p>
            <p style="text-align: center">
              联系我们：010-53323336
            </p>
            <p style="text-align: center">
              <a style="color: #BBBBBB;" target="_blank" href="https://beian.miit.gov.cn/">京ICP备2024087343号</a>
            </p>
          </div>
        </div>
      </div>
    </footer>

    <!-- Back To Top Start -->
    <div class="scroll-up">
      <svg class="scroll-circle svg-content" width="100%" height="100%" viewbox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterPage',
}
</script>
