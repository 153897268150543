export default {

    //首页轮播图
    swiperData: [
        {
            id:1,
            image:'https://file.me-moments.com/big3/banner1.png',
            oneText:'打造经典赛事新名片',
            twoText:'焕新城市文旅新流量',
            videoUrl:'https://file.me-moments.com/big3/index_video.mp4'
        },
        {
            id:2,
            image:'https://file.me-moments.com/big3/banner2.png',
            oneText:'打造经典赛事新名片',
            twoText:'焕新城市文旅新流量',
            videoUrl:'https://file.me-moments.com/big3/index_video.mp4'
        },
        {
            id:3,
            image:'https://file.me-moments.com/big3/banner3.png',
            oneText:'打造经典赛事新名片',
            twoText:'焕新城市文旅新流量',
            videoUrl:'https://file.me-moments.com/big3/index_video.mp4'
        }
    ],

    //首页平台
    platformData: [
        {
            id:1,
            text:'抖音',
            icon:'https://file.me-moments.com/big3/douyin.png',
            url:'https://www.douyin.com/user/MS4wLjABAAAA7oipvTFPArufMBt28v6UaMU54GlRe-VuzSyL5PvvgsjQpOjA3yuqvvP6QoT6bdDv?from_tab_name=main',
        },
        {
            id:2,
            text:'快手',
            icon:'https://file.me-moments.com/big3/kuaishou.png',
            url:'https://www.kuaishou.com/profile/3xu3ek69xegdee9',
        },
        {
            id:3,
            text:'视频号',
            icon:'https://file.me-moments.com/big3/shipinhao.png',
            url:'',
        },
        {
            id:4,
            text:'B站',
            icon:'https://file.me-moments.com/big3/bzhan.png',
            url:'https://space.bilibili.com/1587130810?spm_id_from=333.337.0.0',
        }
    ],
    platformData2: [
        {
            id:1,
            text:'小红书',
            icon:'https://file.me-moments.com/big3/xiaohongshu.png',
            url:'https://www.xiaohongshu.com/user/profile/664aa99300000000030318bd?xhsshare=WeixinSession&appuid=59b2a7295e87e76405608ead&apptime=1729494189&share_id=fe7bdaba3a96432b84e83f1a3d56349c&wechatWid=484c9f2e756222f70448d832aa4133ba&wechatOrigin=menu',
        },
        {
            id:2,
            text:'得物',
            icon:'https://file.me-moments.com/big3/dewo.png',
            url:'https://m.dewu.com/h5-sociality/community/user-home-page/hybird/h5other/shareMiddle?userId=dPYdpW3&shareId=W6reWE&source=homePage',
        },
        {
            id:3,
            text:'微博',
            icon:'https://file.me-moments.com/big3/weibo.png',
            url:'https://m.weibo.cn/u/7094563053?wm=3333_2001&from=10EA193010&sourcetype=weixin&s_trans=6088949986_&s_channel=4',
        },
        {
            id:4,
            text:'公众号',
            icon:'https://file.me-moments.com/big3/gngzhonghao.png',
            url:'',
        }
    ],

    //首页团队资料
    teamData: [
        {
            id:1,
            name:'赛事运营部',
            position:'赛事运营部',
            image:'https://file.me-moments.com/big3/sashi.jpg'
        },
        {
            id:2,
            name:'周边产品部',
            position:'周边产品部',
            image:'https://file.me-moments.com/big3/zhoubian.jpg'
        },
        {
            id:3,
            name:'城市拓展部',
            position:'城市拓展部',
            image:'https://file.me-moments.com/big3/chengshi.jpg'
        },
        {
            id:4,
            name:'媒体宣传部',
            position:'媒体宣传部',
            image:'https://file.me-moments.com/big3/meiti.jpg'
        },
    ],

    teamData2: [
        {
            id:1,
            name:'赛事运营部',
            position:'赛事运营部',
            image:'https://file.me-moments.com/big3/sashi.jpg'
        },
        {
            id:2,
            name:'周边产品部',
            position:'周边产品部',
            image:'https://file.me-moments.com/big3/zhoubian.jpg'
        },
        {
            id:3,
            name:'城市拓展部',
            position:'城市拓展部',
            image:'https://file.me-moments.comfile.me-moments.com/big3/chengshi.jpg'
        },
    ],

    serviceList:[
        {
            id:1,
            oneText:'赛事投资',
            twoText:'',
            content:'投资单季赛事，既能短期（5个月）快速获取回报，又能享受高端赛事提供的各种短期权益，如：VIP观赛，体验良好的视觉享受，不错过任何一个精彩瞬间；颁奖仪式上成为颁奖人；与球星深度交流，以嘉宾身份积极参与球星慈善晚宴活动，拓展人脉资源，为贫困山区青少年篮球事业做贡献；赛事现场主持人介绍投资人或投资企业，提升企业的社会形象和品牌价值等。\n'
        },
        {
            id:2,
            oneText:'品牌赞助',
            twoText:'',
            content:'为赞助企业提供一个具有国际影响力的赛事平台，实现品牌在全国乃至全球范围内的高曝光度，精准触达广泛且热情的篮球爱好者群体，有效提升品牌知名度与美誉度。通过与赛事的紧密结合，赞助商能够展示其企业实力与社会责任感，强化品牌形象，增强消费者对品牌的认同感和忠诚度。同时，赛事所带来的丰富商业合作资源和多元化的营销渠道，为赞助商创造了与其他优质企业互动合作的机会，有助于拓展市场份额，提升产品或服务的销售业绩，进而实现品牌价值与商业利益的双重增长，在激烈的市场竞争中占据更有利的地位。\n'
        },
        {
            id:3,
            oneText:'球星商务活动',
            twoText:'',
            content:'邀请球星代言、探店、企业考察等，提升企业知名度，为民族品牌走向国际开路。邀请国际球星走进网红直播间进行互动，实现跨界融合，助力中国品牌、特色产业、地方文旅进一步走向世界。'
        },
        {
            id:4,
            oneText:'地方政府赞助合作',
            twoText:'',
            content:'通过引入国际知名的三人篮球赛事 IP，推动中国篮球运动的多元化发展与普及，提升中国三人篮球的竞技水平，为篮球人才提供更广阔的展示舞台。同时，促进体育产业与城市经济的融合发展，带动相关产业链的繁荣，提升城市的知名度与影响力，打造城市体育文化新名片。该赛事还将搭建体育文化交流平台，促进国内外篮球文化的交流与互鉴，激发民众对体育运动的热情，增强城市居民的凝聚力和归属感，为中国体育事业的全面发展和城市的综合建设注入新的活力与动力。\n'
        }
    ],

    //新闻
    newsList:[
        {
            rowId:1,
            data:[
                {
                    id:1,
                    tag:'传奇',
                    title:'打造经典 致敬传奇',
                    image:'https://file.me-moments.com/big3/n1.png',
                    day:'21',
                    month:'十月',
                    content:'<p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                      近日，中焱体育获美国顶级三对三BIG3联盟中国运营独家授权，整体负责在中国地区组织 BIG3篮球赛事、商业开发、新媒体运营、IP运营等系列业务。作为独家运营商，中焱体育致力打造经典赛事，将承担起在中国推广发展BIG3联赛、推动篮球文化普及提升的重任。这样的合作既有助于BIG3联赛在中国市场深度拓展，更将为中国篮球带来全新发展机遇和商业价值。\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                        BIG3 联赛是三人篮球联赛里最有影响力的赛事，由美国NBA巨星阿伦·艾弗森于2017年与说唱巨星艾斯·库伯创办，得到众多NBA退役巨星加盟支持，自成立以来发展迅速，引起极高关注和轰动，已成为全美仅次于NBA的篮球赛事。目前有签约球队12支，由NBA退役球星以及FIBA3X3等各大联赛球员参与，成为NBA退役球星发挥影响力、宣传篮球文化和体育精神的顶级平台，受到全球越来越多的篮球爱好者青睐追捧。Big3联赛在中国市场的拓展无疑将吸引大量篮球爱好者的关注。\n' +
                        '                    </p>\n' +
                        '                    <h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;三人篮球的中国之路</h4>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                        三人篮球自上20世纪90年代传入我国，经历了从起步到逐渐壮大的过程。2014我国颁布《国务院关于加快体育产业促进体育消费的若干意见》，赛事的运营水平、运动员的竞技水平等开始与国际接轨，三人篮球也开始加速发展。特别是2017年国际奥委会将三人篮球纳入奥运会正式比赛项目后，国际篮联加大了对三人篮球的推广力度，中国篮协也积极响应，成立了三人篮球办公室，加强国内推广和国际交流。我国三人男篮和三人女篮都参加了东京奥运会，中国三人女篮获铜牌，中国男、女队均凭借世界排名直通巴黎奥运会，展现了中国三人篮球的强大实力、潜力。\n' +
                        '                    </p>\n' +
                        '                    <h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;决心与愿景：共创篮球新篇章</h4>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                        创新是我国三人篮球发展的特点与趋势。虽然当前我国三人篮球的竞技水平有了较大提升，但同高水平国家相比仍有一定差距，赛事宣传和推广力度不尚足，后备培养需长远规划。如何在当前三人篮球迅猛发展的势头下提升我国竞赛成绩、提高赛事影响力和关注度、挖掘后备人才、提高赛事的商业价值，是当前三人篮球发展的重点。\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                        刚刚结束的巴黎奥运会让我们深信，体育精神无国界。中国有着欣欣向荣的青少年队伍、热情的球迷和良好的篮球文化氛围，BIG3登陆中国只是一个开端，未来将加大深耕中国市场，让BIG3在中国落地开花、喜闻乐见，除中国赛以外，包括青少年联赛、篮球培训、慈善赛及社会慈善活动等方面都将开展起来，创造更多的平台让更多的小朋友和年轻人可以接触明星球员、明星教练，热爱篮球、享受篮球，进而帮助中国篮球运动更好的发展。\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                        中焱体育坚持“以人民为中心”的体育发展理念，坚守为中国体育事业贡献力量的初心，希望通过引入国际顶级三人篮球赛事，加大与传统媒体以及新媒体的合作，将BIG3与中国文化相结合，吸引更多的人关注并参与三人篮球，打造一个适应中国市场且真正属于中国的经典IP赛事，共创中国篮球新篇章。我们完全有理由期待并相信，这是一项具有广阔发展前景、高度使命意义和极佳商业价值的阳光产业。\n' +
                        '                    </p>',
                },
                {
                    id:2,
                    tag:'传奇',
                    title:'三人篮球：速度激情的碰撞 体育精神的盛宴',
                    image:'https://file.me-moments.com/big3/n2.png',
                    day:'22',
                    month:'十月',
                    content: '<p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                      2024年巴黎奥运会上，三人篮球第二次作为正式项目亮相于巴黎协和广场，并且首次有现场观众参与。整场赛事不仅是充满突破与纪录的竞技盛宴，更成为展现篮球文化和体育精神的舞台，再次吸引全球关注，激励更多篮球爱好者投身这项运动。\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                      作为三人篮球领域的知名赛事，BIG3联赛对三人篮球在世界范围的快速普及和蓬勃发展发挥了重要作用。自2017年首季联赛成功举办以来，BIG3凭借其独特的赛制、兼具竞技和娱乐的比赛形式，吸引了众多NBA退役球星和顶级三人篮球运动员的加盟与参与，已成为全美仅次于NBA的篮球赛事。通过与国际篮联（FIBA）等篮球组织机构合作、举办选秀大会以及广泛的媒体曝光、赛事宣传和商业运营，BIG3为更多有潜力的年轻球员提供展示自我的机会，成功将三人篮球运动带入更多人的视野，推动三人篮球成为篮球领域的重要力量。\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                      篮球不息，热爱不止。篮球文化集历史渊源、竞技魅力、团队精神、社会影响等内涵于一体，早已在世界范围深入人心。三人篮球之所以吸引人，既源于其竞技特点，更因为它深刻体现了篮球运动的核心精神，为篮球文化注入了新的活力。\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                      一方面，从竞技规则看：三人篮球比赛场地较小，有一条罚球线和两分线（没有三分线），线内得分包括罚球算1分，线外得分算2分，率先得到21分的队伍获胜，常规比赛时间只有10分钟，没有暂停，球队上场3人，替补1人，替补球员可以随时上场替换一名球员。如此快节奏的比赛让每一分都至关重要，每一次进攻都可能是绝杀，攻防转换往往只在瞬息之间，灵活的换人机制又使比赛更加充满变数，赛事现场全程紧凑且富有悬念。 因此，三人篮球具有极高的观赏性，令观众目不暇接，全程充满紧张感和刺激感，甚至肾上腺素飙升。\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                      另一方面，从技战术要求看：三人篮球没有明确的位置限制，要求每一个球员掌握全面的技术，且教练员不能进入场边指导，换人与战术变化全靠球员自行决定，球员们需要在有限的时间内达到最佳状态，充分展现个人能力，这种自由性让比赛更加灵活多变。面对各种挑战和困难，如对手的紧逼防守、高强度的身体对抗等，球员们需要具备出色的反应速度和应变能力，需要迅速判断对手的动向和意图，从而争取每一个得分机会。因此球员需要在比赛中保持高度专注，团队需要默契配合，不断寻找突破机会，才能取得胜利。\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                      作为三人篮球运动在中国的重要推广方，BIG3中国运营团队更想跟广大球迷分享这项运动所蕴含的体育精神、篮球精神：\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                      一是竞技精神。三人篮球是一项竞技性极强的运动，充满了激烈竞争，球员们在场上全力以赴争夺每一个球权，每个失误都可能对比赛结果产生重大影响，因此十分考验球员的抗压能力。他们拼搏奋斗、勇于挑战、绝不服输的态度，恰展现了体育竞技的魅力和价值。\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                      二是团队协作精神。团队协作在三人篮球中尤为重要，快节奏、高强度的比赛更需要球员紧密配合，共同制定战术，并灵活调整。赛场上经常可以看到球员们通过默契的配合和无私的传球，创造出精彩的得分机会。只有相互信任、支持和协作，才能共同完成攻防任务。\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                      三是公平竞争精神。三人篮球强调尊重比赛规则，尊重对手的实力和表现，尊重裁判的判决，接受比赛结果，不进行恶意犯规或挑衅行为，这种公平竞争的精神是体育竞技的重要内涵，有助于维护比赛的公正性和观赏性，也是篮球运动得以持续发展的基石。\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                      四是自我超越精神。三人篮球不仅是一项竞技运动，更是自我超越的运动。赛场上，球员需要保持高度的专注、坚定的信念，不断应对比赛中的变化和挑战，不放弃任何一个球权，不断突破自己的极限。同时，BIG3联盟的很多球星都是NBA退役球员，他们这种始终热爱、坚韧不拔的精神，正是三人篮球的光芒，是体育竞技不可或缺的品质。\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                      因此，三人篮球比赛真正是展现无畏竞技、极致协作、追求卓越与公正精神的现场，这些精神不仅激励着球员们在比赛中发挥出最佳水平，也为观众带来了精彩绝伦的观赛体验，无时无处不在传递积极向上的价值观和正能量。\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                      我们坚信，三人篮球未来发展前景广阔。一方面，它将继续作为国际篮联的重要赛事之一，吸引更多国家和地区的顶尖球队参赛，激发更多中国青少年的参与热情；另一方面，三人篮球也将成为大众体育和社会文化的重要组成部分，随着体育与文旅产业的深度融合发展，三人篮球将成为推动地方经济和文化发展、促进国际交流合作的重要力量。BIG3中国运营团队将秉持信念，以推进篮球事业、贡献体育强国为使命，为中国的篮球爱好者和青少年们带来更多精彩的赛事活动、专业的培训项目，让三人篮球和体育精神进一步丰富人民的美好生活！\n' +
                        '                    </p>'
                },
                {
                    id:3,
                    tag:'传奇',
                    title:'致好友的一封信',
                    image:'https://file.me-moments.com/big3/n3.png',
                    day:'23',
                    month:'十月',
                    content: '<p>\n' +
                        '                      尊敬的领导、挚爱的朋友：\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                      值此金秋时节，我们很荣幸能与您分享一份合作意向书。\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                      我们于近期拿到了全球顶级三人篮球商业联盟BIG3的中国区独家运营授权。BIG3是NBA退役球星艾佛森和说唱歌手艾斯库伯创办的3v3篮球赛事，获得麦迪、加内特、雷阿伦、德雷克斯勒等在内的众多NBA顶级球星支持参与，已成为全美仅次于NBA的顶级篮球赛事和全球最具影响力及观赏度的三人篮球赛事，自2017年成立以来在欧美市场已经取得了极大的成功。\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                      如今我们把这个赛事带到中国，旨在打造一个适应中国市场的经典IP赛事，承担在中国推广发展BIG3联赛、推动三人篮球文化普及提升的使命。我们将整体负责在BIG3在中国的篮球赛事组织、商业开发、顶级IP运营等业务，深度拓展BIG3联赛在中国市场，为中国篮球带来全新发展机遇和商业价值。\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                      近年来，党和国家高度重视大众体育和全民健身，我们敏锐把握并紧跟国家关于建设体育强国、推动青少年身体素质培训、加快发展体育产业、促进中外体育交流、培育经济发展新动能等清晰政策导向。我们和新华网达成深度战略合作，依托其强大媒体资源、权威影响力、优质内容价值和丰富传播渠道，引入国际知名体育IP，为赛事举办城市提高国际知名度，协同政府打造城市体育新名片，为体育产业和区域经济发展注入新动能。同时，BIG3中国新媒体运营现阶段在全网已有2000多万的粉丝量，我们已经和新华网、抖音、快手、B站、小红书等顶流媒体平台达成战略合作关系， 在用好传统媒体资源优势的基础上，我们将通过持续的新媒体运营活动，进一步拓宽用户群体、提升品牌知名度，不断提升品牌价值、市场地位和盈利能力，为长期发展提供有力支持。\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                      明年BIG3•中国计划在全国多个城市举办国际友谊赛，邀请BIG3顶级球星/球队来华，为中国篮球粉丝提供最高水平三人篮球盛宴，拉进BIG3品牌与海量球迷的距离，为更多的篮球爱好者提供平台。我们计划在国内推出NBA退役巨星及教练面向国内青少年的篮球培训。将NBA的篮球培训体系和中国青少年的篮球教培有机结合，提供青少年线上线下融合教学，选拔优秀青少年队员赴美篮球游学，创造更多平台让更多的小朋友和年轻人可以接触明星球员、明星教练。我们计划推出BIG3周边产品包括球星卡、运动毛巾、BIG3的T恤和运动鞋等产品，将品牌的影响力扩展到更广泛的领域，提升品牌的附加值和整体盈利水平。\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
                        '                      特此与好朋友们诚挚分享这份开启新征程的喜悦。我们完全有理由期待并相信，这是一项具广阔发展前景和极佳商业价值的阳光产业。如有品牌赞助或明星合作等商业活动意向的朋友们可以联系我们，非常期待合作共赢。\n' +
                        '                    </p>'
                }
            ]
        },
        // {
        //     rowId:2,
        //     data:[
        //         {
        //             id:1,
        //             tag:'Logistics',
        //             title:'Fast And Reliable Shipping Guaranteey Trusted',
        //             image:'/image/041.jpg',
        //             day:'20',
        //             month:'一月'
        //         },
        //         {
        //             id:2,
        //             tag:'Logistics',
        //             title:'Fast And Reliable Shipping Guaranteey Trusted',
        //             image:'/image/041.jpg',
        //             day:'20',
        //             month:'一月'
        //         },
        //         {
        //             id:2,
        //             tag:'Logistics',
        //             title:'Fast And Reliable Shipping Guaranteey Trusted',
        //             image:'/image/041.jpg',
        //             day:'20',
        //             month:'一月'
        //         }
        //     ]
        // }
    ],

    //照片
    photoList:[
        {
            id:1,
            image:'https://file.me-moments.com/big3/p1.png',
            tag:'传奇',
            title:'乔·约翰逊'
        },
        {
            id:2,
            image:'https://file.me-moments.com/big3/p2.png',
            tag:'对抗',
            title:'比斯利VS布鲁尔'
        },
        {
            id:3,
            image:'https://file.me-moments.com/big3/p3.png',
            tag:'不朽',
            title:'杰森·理查德森'
        }
    ],

    photoDetail:[
      'https://file.me-moments.com/big3/a1.jpg',
      'https://file.me-moments.com/big3/a2.jpg',
      'https://file.me-moments.com/big3/a3.jpg',
      'https://file.me-moments.com/big3/a4.jpg',
      'https://file.me-moments.com/big3/a5.jpg',
      'https://file.me-moments.com/big3/a6.jpg',
      'https://file.me-moments.com/big3/a7.jpg',
      'https://file.me-moments.com/big3/a8.jpg',
      'https://file.me-moments.com/big3/a9.jpg',
      'https://file.me-moments.com/big3/a10.jpg',
    ],

    //商品
    goodsList:[
        {
            id:1,
            name:'BIG3篮球',
            image:'https://file.me-moments.com/big3/g1.jpg',
            jumpUrl:'#',
            price:'299'
        },
        {
            id:2,
            name:'BIG3篮球鞋',
            image:'https://file.me-moments.com/big3/g2.jpg',
            jumpUrl:'#',
            price:'699'
        },
        {
            id:3,
            name:'BIG3 T恤',
            image:'https://file.me-moments.com/big3/g3.jpg',
            jumpUrl:'#',
            price:'169'
        }
    ]
}