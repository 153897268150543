<template>
  <div>
    <PreLoader />

    <!--<< Mouse Cursor Start >>-->
    <div class="mouse-cursor cursor-outer"></div>
    <div class="mouse-cursor cursor-inner"></div>

    <!-- Header Area Start -->
    <IndexHeader />


    <!--<< Breadcrumb Section Start >>-->
    <BreadcrumbPage :name="'照片墙'" />

    <!-- Project Section Start -->
    <section class="project-section-5 section-padding fix">
      <div class="project-wrapper-2">
        <div class="container">
          <div class="row g-4">
            <div class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s" v-for="i in DataConfig.photoList" :key="i.id">
              <div class="project-items-3 mt-0">
                <div class="project-image bg-cover" :style="'background-image: url('+i.image+');'">
                  <div class="project-content">
                    <a href="/photoDetail" class="icon">
                      <i class="far fa-arrow-right"></i>
                    </a>
                    <p>{{i.tag}}</p>
                    <h4>
                      <a href="/photoDetail">{{i.title}}</a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer Section Start -->
    <FooterPage />
  </div>
</template>

<script>
import IndexHeader from "@/components/IndexHeader.vue"
import FooterPage from "@/components/FooterPage.vue"
import PreLoader from "@/components/PreLoader.vue"
import DataConfig from "@/views/data/DataConfig"
import BreadcrumbPage from "@/components/BreadcrumbPage.vue"
export default {
  name: 'PhotoList',
  components: {
    IndexHeader,
    PreLoader,
    FooterPage,
    BreadcrumbPage
  },
  computed: {
    DataConfig() {
      return DataConfig;
    },
  },
}
</script>
