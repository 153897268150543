<template>
  <div>
    <PreLoader />

    <!--<< Mouse Cursor Start >>-->
    <div class="mouse-cursor cursor-outer"></div>
    <div class="mouse-cursor cursor-inner"></div>

    <!-- Header Area Start -->
    <IndexHeader />


    <!--<< Breadcrumb Section Start >>-->
    <BreadcrumbPage :name="'新闻'" />

    <!-- News Section Start -->
    <section class="news-section fix section-padding">
      <div class="container">
        <div class="row" v-for="i in DataConfig.newsList" :key="i.rowId">
          <div class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s" v-for="j in i.data" :key="j.id">
            <div class="single-news-items-2">
              <div class="news-image bg-cover" :style="'background-image: url('+j.image+');'">
                <div class="post-date">
                  <h5>{{j.day}}</h5>
                  <span>{{j.month}}</span>
                </div>
                <div class="news-content">
                  <div class="icon">
                    <i class="fal fa-tag"></i>
                    <span>{{j.tag}}</span>
                  </div>
                  <h4>
                    <a :href="'/newsDetail?id='+j.id">
                      {{j.title}}
                    </a>
                  </h4>
                  <ul class="post-list">
                    <li><i class="far fa-comment-alt"></i> <span>查看详情</span></li>
                    <li><a :href="'/newsDetail?id='+j.id"><i class="fas fa-long-arrow-right"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer Section Start -->
    <FooterPage />
  </div>
</template>

<script>
import IndexHeader from "@/components/IndexHeader.vue"
import FooterPage from "@/components/FooterPage.vue"
import PreLoader from "@/components/PreLoader.vue"
import DataConfig from "@/views/data/DataConfig"
import BreadcrumbPage from "@/components/BreadcrumbPage.vue"
export default {
  name: 'NewsList',
  components: {
    IndexHeader,
    PreLoader,
    FooterPage,
    BreadcrumbPage
  },
  computed: {
    DataConfig() {
      return DataConfig;
    },
  },
}
</script>
