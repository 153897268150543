<template>
  <div>
    <PreLoader />

    <!--<< Mouse Cursor Start >>-->
    <div class="mouse-cursor cursor-outer"></div>
    <div class="mouse-cursor cursor-inner"></div>

    <!-- Header Area Start -->
    <IndexHeader />

    <!--<< Breadcrumb Section Start >>-->
    <BreadcrumbPage :name="'联系我们'"/>

    <!-- Contact Info Section Start -->
    <section class="contact-page-wrap section-padding" style="padding: 140px 0;">
      <div class="container">
        <div class="row g-4">
          <div class="col-lg-4 col-md-6 col-12">
            <div class="single-contact-card card1">
              <div class="top-part">
                <div class="icon">
                  <i class="fal fa-envelope"></i>
                </div>
                <div class="title">
                  <h4>邮箱</h4>
                  <span>随时发送邮件</span>
                </div>
              </div>
              <div class="bottom-part">
                <div class="info">
                  <p>china_big3@163.com</p>
                </div>
                <div class="icon">
                  <i class="fal fa-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <div class="single-contact-card card2">
              <div class="top-part">
                <div class="icon">
                  <i class="fal fa-phone"></i>
                </div>
                <div class="title">
                  <h4>联系电话</h4>
                  <span>请随时联系我们</span>
                </div>
              </div>
              <div class="bottom-part">
                <div class="info">
                  <p>010-53323336</p>
                  <p>18613842310</p>
                </div>
                <div class="icon">
                  <i class="fal fa-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <div class="single-contact-card card3">
              <div class="top-part">
                <div class="icon">
                  <i class="fal fa-map-marker-alt"></i>
                </div>
                <div class="title">
                  <h4>地址</h4>
                  <span>欢迎来访</span>
                </div>
              </div>
              <div class="bottom-part">
                <div class="info">
                  <p>北京市朝阳区金台2号</p>
                  <p>人民日报社18号楼3层</p>
                </div>
                <div class="icon">
                  <i class="fal fa-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer Section Start -->
    <FooterPage />
  </div>
</template>

<script>
import IndexHeader from "@/components/IndexHeader.vue"
import FooterPage from "@/components/FooterPage.vue"
import PreLoader from "@/components/PreLoader.vue"
import DataConfig from "@/views/data/DataConfig"
import BreadcrumbPage from "@/components/BreadcrumbPage.vue"
export default {
  name: 'ContactPage',
  components: {
    IndexHeader,
    PreLoader,
    FooterPage,
    BreadcrumbPage
  },
  computed: {
    DataConfig() {
      return DataConfig;
    },
  },
}
</script>
