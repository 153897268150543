<template>
  <div>
    <div id="preloader" class="preloader">
      <div class="animation-preloader">
        <div class="spinner">
        </div>
        <div class="txt-loading">
          <span data-text-preloader="B" class="letters-loading">B</span>
          <span data-text-preloader="I" class="letters-loading">I</span>
          <span data-text-preloader="G" class="letters-loading">G</span>
          <span data-text-preloader="3" class="letters-loading">3</span>
          <span data-text-preloader="中" class="letters-loading">中</span>
          <span data-text-preloader="国" class="letters-loading">国</span>
        </div>
        <p class="text-center">加载中</p>
      </div>
      <div class="loader">
        <div class="row">
          <div class="col-3 loader-section section-left">
            <div class="bg"></div>
          </div>
          <div class="col-3 loader-section section-left">
            <div class="bg"></div>
          </div>
          <div class="col-3 loader-section section-right">
            <div class="bg"></div>
          </div>
          <div class="col-3 loader-section section-right">
            <div class="bg"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreLoader',
}
</script>
